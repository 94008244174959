import React from "react"
import { Card, CardContent, CardActionArea, Grid, Paper, Typography} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../components/layout';
import Footer from '../components/footer';
import SEO from '../components/seo';

import "../components/blockquote.css";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    minHeight: "350px",
    //background: "linear-gradient(to bottom, #fff, #4A6476)"
  },
  gridContainer: {
    width: "100%",
    marginTop: "56px",
    //minHeight: "300px",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignItems: "center",
  },
  gridContainerSecond: {
    width: "100%",
    height: "auto",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(to bottom, #fff, #4A6476)"
  },
  gridContainerThird: {
    width: "100%",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignItems: "flex-start",
    background: "linear-gradient(to bottom,  #4A6476, #1d282f)",
    zIndex: 5,
  },
  paragraph: {
    marginBottom: "10px"
  },
  services: {
    marginBottom: "5px",
    color: "#000",
  },
  mainImage: {
    objectFit: "cover",
    width: "100%",
    maxHeight: "500px",
    height: "300px",

  },

  imageContainer: {
    display: "flex",
    width: "100%",
    position: "relative",

  },
  overlay: {
    position: "absolute",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignItems: "center",
    top:0,
    left: 0,
    background: 'rgba(0,0,0,.6)',
    width: "100%",
    height: "100%",
    zIndex: 2,
    overflow:"hidden",
  },
  contentContainer: {
    display: "flex",
    maxWidth: "1200px",
    height: "auto",
    direction: 'row',
    justifyContent: "center",
    alignItems: "center",
  },
  practiceContainer: {
    paddingTop: "50px",
    paddingBottom: "50px",
    direction: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "12px",
      marginRight: "12px",
    },
  },
  headline: {
    color: "#fff",
    zIndex:5,
    width: "100%",
    textAlign: "center",
  },
  practiceArea: {
    padding: "20px",
    color: "#000",
    zIndex:5,
    width: "100%",
    textAlign: "center",
  },


  practiceCard: {
    background: "linear-gradient(to bottom, #1d282f, #4A6476)",
  },
  practiceCardTitle: {
    color: "#fff",
    textAlign: "center"
  },
  media: {
    height: 150,
    filter: "grayscale(90%)"
  },
  testimonialItem: {
    height: "auto",
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    direction: "column",
    //minHeight: "250px",
    width: "100%",
    opacity: 1,
  	animationName: "fadeInOpacity2",
  	animationIterationCount: 1,
  	animationTimingFunction: "ease-in",
  	animationDuration: "1s",
  },
  testimonials: {
    padding: "20px",
    color: "#fff",
    zIndex:5,
    width: "100%",
    textAlign: "center",
  },
  testimonialHeading: {
    color: "#fff",
    textAlign: "center",
    alignSelf: "center",
    width: "100%",
  },

});



class RealEstate extends React.Component {
  constructor(props) {
  super(props);
    this.state = {


    };

  }


  renderServiceAreas(){
    const {classes, data } = this.props;
    let areas = data.allContentfulRealEstatePage.edges[0].node.serviceAreas;
    return areas.map((area, index)=>{
      return (
        <Grid key = {index} className={classes.testimonialItem} container item xs={12} md={4}>
          <Typography className={classes.testimonialHeading} variant="h6">
            {area}
          </Typography>
        </Grid>
      );
    })
  }

  renderCards(){
    const {classes, data } = this.props;
    let cards = data.allContentfulRealEstatePage.edges[0].node.cards;
    return cards.map(( card )=>{
      return (
        <Grid key={card.id} item xs={12} md={6}>
          <Card raised className={classes.card}>
            <CardActionArea>
              <CardContent className={classes.practiceCard}>
                <Typography className={classes.practiceCardTitle} gutterBottom variant="h6" component="h2">
                  {card.cardTitle}
                </Typography>
              </CardContent>
              <Paper className={classes.root}>
                <Typography variant="subheading" className={classes.paragraph}
                dangerouslySetInnerHTML={{
                    __html: card.cardBody.childMarkdownRemark.html,
                  }}
                />
              </Paper>
            </CardActionArea>
          </Card>
        </Grid>
      );
    })
  }




  render(){

    const {classes, data } = this.props;
   
    let image  = data.allContentfulRealEstatePage.edges[0].node.image.file.url;
    let title = data.allContentfulRealEstatePage.edges[0].node.title;
    let SEOareas = data.allContentfulRealEstatePage.edges[0].node.serviceAreas;
   
    let seoDescription = '';
    for (var x = 0; x < SEOareas.length; x++){
      if (x < SEOareas.length - 1){
        seoDescription += (SEOareas[x] + ", ")
      } else {
        seoDescription += (SEOareas[x])
      }
    }
    return (
      <Layout>
        <SEO pagePath ="/real-estate/"  pageTitle = "Real Estate" keywords = {`Attorney - Real Estate, Cook County, Lake County, McHenry County, Kane County and DuPage County, ${seoDescription}`} pageDescription = {seoDescription} image = {data.shareImage.childImageSharp.resize.src} imageHeight="400" imageWidth="400"/>
        <Grid className = {classes.gridContainer} container  >
          <Grid className= {classes.imageContainer} container>
            <img className={classes.mainImage} alt={image} src = {`${image}?w=1200&q=50`}/>
            <Grid container className={classes.overlay}>
              <Grid container className={classes.contentContainer} spacing={24}>
                <Grid item xs={12} >
                  <Typography className={classes.headline} variant="h3" >
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          </Grid>
          <Grid className = {classes.gridContainerThird} container>
            <Grid container className={classes.contentContainer} spacing={24}>
              <Grid className={classes.practiceContainer} container spacing={24} >
                {this.renderServiceAreas()}
              </Grid>
            </Grid>
          </Grid>
          <Grid className = {classes.gridContainerSecond} container>
            <Grid container className={classes.contentContainer} spacing={24}>

              <Grid className={classes.practiceContainer} container spacing={24} >
                {this.renderCards()}

              </Grid>
            </Grid>
          </Grid>



          <Footer/>
      </Layout>
    );
  }

}


export default withStyles(styles)(RealEstate);

export const pageQuery = graphql`
  query {
    allContentfulRealEstatePage {
      edges {
        node {
          id
          title
          image {
            id
            file {
              url
            }
          }
          serviceAreas
          cards {
            id
            cardTitle
            cardBody {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
    shareImage: file(relativePath: {eq: "Kaiser.jpg"}) {
      childImageSharp {
        resize(width: 400, height: 400) {
          src
        }
      }
    }
  }
`
